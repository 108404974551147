.plane-container {
    margin: 20vh auto;
    display: grid;
    width: 90vw;
  
    &.direct {
      grid-template-columns: 2fr 8fr;
  
      .plane-details {
        order: 1;
      }
      .plane-image {
        order: 2;
      }
    }
    &.reverse {
      grid-template-columns: 8fr 2fr;
      .plane-details {
        order: 2;
      }
      .plane-image {
        order: 1;
      }
    }
    .plane-image {
      height: 90vh;
  
      img {
        display: none;
      }
    }
  }
  
  .plane-details {
    width: 140px;
    align-self: center;
    justify-self: center;
    h6 {
      font-size: 20px;
      font-weight: 500;
    }
    p {
      font-size: 12px;
      line-height: 14.5px;
      color: #666;
    }
  
    .vertical-line {
      height: 1px;
      width: 50px;
      background-color: #424242;
      margin: 20px 0 25px;
    }
  }
  .Plane {
    width: 60vw;
    height: 60vh;
    margin: 20vh auto;
  }
  
  .Plane img {
    display: none;
  }