@tailwind base;
@tailwind components;
@tailwind utilities;




.clip-path {
  clip-path: ellipse(26% 50% at 50% 50%); 
}

.clip-path-2 {
  clip-path: ellipse(22% 50% at 50% 50%); 
}

.clip-path-3 {
  clip-path: ellipse(99% 50% at 0% 50%);
}

.clip-path-right {
  clip-path: ellipse(74% 50% at 0% 50%);
}

.shadow-blue-white {
  -webkit-box-shadow: 150px 150px 250px 50px #e7dada, -100px -70px 270px 1px #1d20db, 110px 120px 470px 140px rgba(0,0,0,0.43); 
  box-shadow: 150px 150px 250px 50px #e7dada, -100px -70px 270px 1px #1160bb, 110px 120px 470px 140px rgba(0,0,0,0.43);
}

.text-stroke {
  color: #D7C200;
  -webkit-text-fill-color: black; 
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #D7C200;
}


/* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;600;700&family=Poppins:wght@300;400;700&display=swap'); */

/* @font-face {
    font-family: 'HARMONY';
    src: url('./assets/fonts/HARMONY.otf');
} */
@font-face {
    font-family:' Rockstar';
    src: url('./assets/fonts/Rockstar-ExtraBold.otf');
}


@font-face {
    font-family: 'bebasNeue';
    src: url('./assets/fonts/BebasNeue-Regular.ttf');
}
@font-face {
    font-family: 'bebasB';
    src: url('./assets/fonts/Fontfabric-BebasNeue-Bold.ttf');
}

@font-face {
    font-family: "Open Sauce";
    src: url("./assets/fonts/opensauce-medium.woff2") format("woff2"),
      url("./assets/fonts/opensauce-medium.woff2") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
/*   
  @font-face {
    font-family: "Open Sauce";
    src: url("./assets/fonts/opensauce-regular.woff2") format("woff2"),
      url("./assets/fonts/opensauce-regular.woff2") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  } */
  
  @font-face {
      font-family: 'Pixer' ;
      src: url('./assets/fonts/Pixer-Regular.woff') format("woff2");
    
  }
  @font-face {
      font-family: 'Pixer2' ;
      src: url('./assets/fonts/Pixer-Regular.woff2') format("woff2");
  }
